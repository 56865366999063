import Layout from "../components/Layout";
import PageNotFound from "../components/Error/404";
import PageHeader from "../components/PageHeader";

const PageNotFoundPage = (): JSX.Element => (
  <Layout>
    <div className="bg-secondary">
      <PageHeader title={"Pagina niet gevonden"} />

      <main className="page">
        <section id="hoe-lid-worden">
          <PageNotFound />
          <p>
            Pagina niet gevonden. Denk je dat dit een fout is,{" "}
            <a href="mailto:info@bmxdevils.be">laat het ons weten!</a>
          </p>
        </section>
      </main>
    </div>
  </Layout>
);

export default PageNotFoundPage;
